export const free = {
    name: 'Free',
    description: 'For individuals. Everything you need to get started with Voiceform',
    priceIds: [],
    shortFeaturesList: [
        'Unlimited Voiceforms',
        'Unlimited Questions',
        'Voice Response, Audio Question',
        'Standard Embedding',
        'Colors Customization',
        'Color Themes',
        'Hide Progress Bar'
    ],
    featuresMap: {
        transcription: false,
        transcription_languages: false,
        transcription_processing: true,
        translation: false,
        survey_translations: false,
        all_question_types: true,
        email_notifications: true,
        analytics: true,
        audio_analytics: false,
        all_embedding_options: true,
        remove_branding: false,
        upload_logo: true,
        upload_welcome_image: true,
        data_export: false,
        audio_export: false,
        custom_labels: true,
        forms_duplication: true,
        max_audio_response_time: 60 * 2.5,
        share_email_by_csv: false,
        schedule_voiceform_end_date: true,
        image_attachment: true,
        video_attachment: true,
        redirect: true,
        file_upload: false,
        partial_responses: false,
        partial_responses_auto_submit: false,
        data_mapping: false,
        matrix_question: true,
        dynamic_loops: false,
        condition_scripts: false,
        email_submission_confirmation: true,
    },
}

export const freeTrial = {
    name: 'Free Trial',
    description: 'Try Voiceform in your workflow. Access all Voiceform features for 15 days!',
    priceIds: [],
    shortFeaturesList: [
        'Sentiment Analysis',
        'All Question Types',
        'All Analytics & Transcription Features',
        'All Audio & Data Export Features',
        'All Sharing & Embed Types',
    ],
    featuresMap: {
        transcription: true,
        transcription_languages: true,
        transcription_processing: true,
        translation: false,
        survey_translations: false,
        all_question_types: true,
        email_notifications: true,
        analytics: true,
        audio_analytics: true,
        all_embedding_options: true,
        remove_branding: true,
        upload_logo: true,
        upload_welcome_image: true,
        data_export: true,
        audio_export: true,
        custom_labels: true,
        forms_duplication: true,
        max_audio_response_time: 60 * 2.5,
        share_email_by_csv: false,
        schedule_voiceform_end_date: true,
        image_attachment: true,
        video_attachment: true,
        redirect: true,
        file_upload: false,
        partial_responses: false,
        partial_responses_auto_submit: false,
        data_mapping: false,
        matrix_question: true,
        dynamic_loops: false,
        condition_scripts: false,
        email_submission_confirmation: true,
    },
}

export const essentials = {
    name: 'Essentials',
    description: 'For small teams and startups. Access enhanced analytics and automation capabilities',
    priceIds: [
        'price_1KoVwNBGs7Kj5jB6FJZQl4bC',
        'price_1Ko81VBGs7Kj5jB6sdMnBGOb',
        'price_1KoVwNBGs7Kj5jB6i1EssbKd',
        'price_1Ko81VBGs7Kj5jB6ZIfxHrv7',
        'price_1LTFQ9BGs7Kj5jB6Mszifti7',
        'price_1LTFP6BGs7Kj5jB6n6PughZh',
        'price_1LTTceBGs7Kj5jB6NsFuzhIg',
        'price_1LTTdaBGs7Kj5jB6JCa43lre',
    ],
    shortFeaturesList: [
        'Everything in Free Plan',
        'Audio analytics',
        'Audio & Data Export'
    ],
    featuresMap: {
        transcription: true,
        transcription_languages: true,
        transcription_processing: true,
        translation: false,
        survey_translations: false,
        all_question_types: true,
        email_notifications: true,
        analytics: true,
        audio_analytics: true,
        all_embedding_options: true,
        remove_branding: false,
        upload_logo: true,
        upload_welcome_image: true,
        data_export: true,
        audio_export: true,
        custom_labels: true,
        forms_duplication: true,
        max_audio_response_time: 60 * 5,
        share_email_by_csv: true,
        schedule_voiceform_end_date: true,
        image_attachment: true,
        video_attachment: true,
        redirect: true,
        file_upload: true,
        partial_responses: false,
        partial_responses_auto_submit: false,
        data_mapping: false,
        matrix_question: true,
        dynamic_loops: false,
        condition_scripts: false,
        email_submission_confirmation: true,
    },
}

export const pro = {
    name: 'Pro',
    description: 'For growing teams that need to scale their responses. Access increased response volumes, and customize branding.',
    priceIds: [
        'price_1KoVytBGs7Kj5jB6zKH2yITu',
        'price_1KoALZBGs7Kj5jB6XwtymswB',
        'price_1KoVytBGs7Kj5jB6uoUYGJNH',
        'price_1KoALZBGs7Kj5jB6D55f9LBY',
        'price_1LTFkjBGs7Kj5jB6sMgQ9qdC',
        'price_1LTFjABGs7Kj5jB6SUM23DVM',
        'price_1LTTaIBGs7Kj5jB6oDlOOkyJ',
        'price_1LTTb1BGs7Kj5jB6Wh2qWDyx',
    ],
    shortFeaturesList: [
        'Everything in Essentials Plan',
        'Remove Voiceform Branding',
    ],
    featuresMap: {
        transcription: true,
        transcription_languages: true,
        transcription_processing: true,
        translation: false,
        survey_translations: false,
        all_question_types: true,
        email_notifications: true,
        analytics: true,
        audio_analytics: true,
        all_embedding_options: true,
        remove_branding: true,
        upload_logo: true,
        upload_welcome_image: true,
        data_export: true,
        audio_export: true,
        custom_labels: true,
        forms_duplication: true,
        max_audio_response_time: 60 * 10,
        share_email_by_csv: true,
        schedule_voiceform_end_date: true,
        image_attachment: true,
        video_attachment: true,
        redirect: true,
        file_upload: true,
        partial_responses: false,
        partial_responses_auto_submit: false,
        data_mapping: false,
        matrix_question: true,
        dynamic_loops: false,
        condition_scripts: false,
        email_submission_confirmation: true,
    },
}

export const enterprise = {
    name: 'Enterprise',
    description: 'For growing teams that need to scale their responses. Access increased response volumes, and customize branding.',
    priceIds: [],
    shortFeaturesList: [
        'Everything in Pro Plan',
        'SSO Authentication',
        'Dedicated Account Manager',
        'Onboarding + Training',
        'Custom Development',
        'Access to Voiceform API',
    ],
    featuresMap: {
        transcription: true,
        transcription_languages: true,
        transcription_processing: true,
        translation: false,
        survey_translations: false,
        all_question_types: true,
        email_notifications: true,
        analytics: true,
        audio_analytics: true,
        all_embedding_options: true,
        remove_branding: true,
        upload_logo: true,
        upload_welcome_image: true,
        data_export: true,
        audio_export: true,
        custom_labels: true,
        forms_duplication: true,
        max_audio_response_time: 60 * 10,
        share_email_by_csv: true,
        schedule_voiceform_end_date: true,
        image_attachment: true,
        video_attachment: true,
        redirect: true,
        file_upload: true,
        partial_responses: false,
        partial_responses_auto_submit: false,
        data_mapping: false,
        matrix_question: true,
        dynamic_loops: false,
        condition_scripts: false,
        email_submission_confirmation: true,
    },
}

export const custom = {
    name: 'Custom',
    description: 'For growing teams that need to scale their responses. Access increased response volumes, and customize branding.',
    priceIds: [],
    shortFeaturesList: [
        'Custom team limits',
        'Transcription',
        'Sentiment Analysis',
        'Onboarding + Training',
        'Dedicated Account Manager',
        'Access to Voiceform API',
    ],
    featuresMap: {
        transcription: true,
        transcription_languages: true,
        transcription_processing: true,
        translation: false,
        survey_translations: false,
        all_question_types: true,
        email_notifications: true,
        analytics: true,
        audio_analytics: true,
        all_embedding_options: true,
        remove_branding: true,
        upload_logo: true,
        upload_welcome_image: true,
        data_export: true,
        audio_export: true,
        custom_labels: true,
        forms_duplication: true,
        max_audio_response_time: 60 * 10,
        share_email_by_csv: true,
        schedule_voiceform_end_date: true,
        image_attachment: true,
        video_attachment: true,
        redirect: true,
        file_upload: true,
        partial_responses: false,
        partial_responses_auto_submit: false,
        data_mapping: false,
        matrix_question: true,
        dynamic_loops: false,
        condition_scripts: false,
        email_submission_confirmation: true,
    },
}

export const customPriceCreationDefaultFeatures = {
    transcription: false,
    transcription_languages: false,
    transcription_processing: true,
    translation: false,
    survey_translations: false,
    all_question_types: true,
    email_notifications: true,
    analytics: false,
    audio_analytics: false,
    all_embedding_options: true,
    remove_branding: false,
    upload_logo: true,
    upload_welcome_image: true,
    data_export: false,
    audio_export: false,
    custom_labels: true,
    forms_duplication: true,
    max_audio_response_time: 60 * 2.5,
    share_email_by_csv: false,
    schedule_voiceform_end_date: true,
    image_attachment: true,
    video_attachment: true,
    redirect: true,
    location: false,
    api_access: false,
    probing: false,
    probing_monthly_limit: 0,
    response_summarization: false,
    file_upload: true,
    partial_responses: false,
    partial_responses_auto_submit: false,
    data_mapping: false,
    matrix_question: true,
    dynamic_loops: false,
    condition_scripts: false,
    email_submission_confirmation: true,
}

export const limitedTimeOffer = {
    name: 'Custom',
    description: 'For growing teams that need to scale their responses. Access increased response volumes, and customize branding.',
    monthly: process.env.VUE_APP_APP_ENV === 'production' ? 'price_1NEJXABGs7Kj5jB6cmurd4Qg' : 'price_1NEJTUBGs7Kj5jB6jPxWHp5X',
    yearly: process.env.VUE_APP_APP_ENV === 'production' ? 'price_1NEJXABGs7Kj5jB6fmmKoZkr' : 'price_1NEJTUBGs7Kj5jB6aQv5NMzd',
    priceIds: [],
    shortFeaturesList: [
        '1 Seat',
        'Unlimited audio responses',
        'Unlimited number of created forms',
        'Unlimited number of questions',
        'All question types are available',
        'Conditional/Skip Logic',
        'Basic Analytics',
    ],
    featuresMap: {
        transcription: false,
        transcription_languages: false,
        transcription_processing: true,
        translation: false,
        survey_translations: false,
        all_question_types: true,
        email_notifications: true,
        analytics: true,
        audio_analytics: false,
        all_embedding_options: true,
        remove_branding: false,
        upload_logo: true,
        upload_welcome_image: true,
        data_export: false,
        audio_export: false,
        custom_labels: true,
        forms_duplication: true,
        max_audio_response_time: 60 * 2.5,
        share_email_by_csv: false,
        schedule_voiceform_end_date: true,
        image_attachment: true,
        video_attachment: true,
        redirect: true,
        file_upload: false,
        partial_responses: false,
        partial_responses_auto_submit: false,
        data_mapping: false,
        matrix_question: true,
        dynamic_loops: false,
        condition_scripts: false,
        email_submission_confirmation: true,
    },
}

export const getContextByPriceId = (stripePriceId) => {
    if (!stripePriceId) {
        return free.featuresMap
    }
    if (essentials.priceIds.includes(stripePriceId)) {
        return essentials
    }

    if (pro.priceIds.includes(stripePriceId)) {
        return pro
    }

    return null
}
